<template>
  <v-dialog
    v-model="show"
    max-width="700px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
  >
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card v-else>
      <v-card-title class="px-0 py-0">
        <v-toolbar
          dark
          color="#7253CF"
          :height="$vuetify.breakpoint.smAndUp ? '103' : '100px'"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title class="modalTitle"
            ><div>Create Student</div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="align-self-baseline" icon @click="show = false">
            <v-icon size="32">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-form class="px-0" ref="form" lazy-validation>
          <v-row style="border-bottom: 1px solid #929292" class="px-4">
            <v-col cols="6" sm="3" style="border-right: 1px solid #929292">
              <div class="profilePicture">
                <div class="profilePictureImage d-flex justify-center">
                  <label for="inputId" ref="imgRef" style="cursor: pointer">
                    <div v-if="!previewImage">
                      <img
                        src="@/assets/publicDonationPage/createStudent/defaultProfile.svg"
                        width="95"
                        alt=""
                      />
                      <img
                        src="@/assets/publicDonationPage/createStudent/plus.svg"
                        width="20"
                        alt=""
                        style="position: relative; right: 28px"
                      />
                    </div>
                    <div v-else>
                      <img
                        :src="previewImage"
                        alt=""
                        width="110"
                        style="max-height: 100px"
                      />
                    </div>
                  </label>
                </div>
                <div
                  class="profilePictureInstruction text-center"
                  @click="$refs.imgRef.click()"
                >
                  <span v-if="!previewImage">Upload Profile Photo</span>
                  <span v-else>Change Profile Photo</span>
                </div>
              </div>
              <!--<v-file-input
                id="inputId"
                style="display: none"
                v-model="imgFile"
                :disabled="formLoading"
                @change="previewProfilePhoto"
                accept="image/*"
                capture="user,environment"
              >
              </v-file-input>-->
              <input
                id="inputId"
                ref="fileInput"
                type="file"
                style="display: none"
                accept="image/*"
                :disabled="formLoading"
                @change="previewProfilePhoto"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <label
                for="inputIds"
                class="labelGallery d-flex justify-center text-center"
              >
                <div class="assetsGallery" v-if="!fileLoader">
                  <div>
                    <img
                      src="@/assets/publicDonationPage/createStudent/galleryPlus.svg"
                      width="25"
                      alt=""
                    />
                  </div>
                  <div style="color: #2c1963">
                    Upload Photos & Videos for Gallery
                  </div>
                </div>
                <div class="assetsGallery" v-else>
                  <h3 style="color: #2c1963; font-size: 13px">
                    Uploading {{ fileCount }}/{{ fileLenght }}
                  </h3>
                  <p style="color: #404040; font-size: 10px">
                    {{ progressPercentage }}%
                  </p>
                  <div class="px-6" style="margin-top: -15px">
                    <v-progress-linear
                      color="#7253CF"
                      indeterminate
                      class="py-0"
                      height="25"
                    ></v-progress-linear>
                  </div>
                </div>
              </label>
              <!--<v-file-input
                id="inputIds"
                style="display: none"
                v-model="imgAssets"
                :disabled="fileLoader"
                @change="uploadAssets"
                accept="image/*,video/*"
                capture="user,environment"
                multiple
              >
              </v-file-input>-->
              <input
                type="file"
                multiple
                style="display: none"
                id="inputIds"
                ref="inputGallery"
                accept="image/*,video/*"
                :disabled="fileLoader"
                @change="uploadAssets"
              />
            </v-col>
            <v-col cols="12" sm="6" v-if="assestStoreList[0]">
              <v-sheet
                class="mx-auto"
                max-width="100%"
                min-height="100%"
                color="#f7f7f7"
              >
                <v-slide-group
                  multiple
                  :show-arrows="$vuetify.breakpoint.smAndUp"
                >
                  <template v-slot:prev>
                    <v-icon color="#D30024">mdi-chevron-left</v-icon>
                  </template>
                  <template v-slot:next>
                    <v-icon color="#D30024">mdi-chevron-right</v-icon>
                  </template>
                  <v-slide-item
                    v-for="(item, i) in assestStoreList"
                    :key="i"
                    class="mx-3"
                  >
                    <div class="imageVideoDiv" v-if="item.type === 'image'">
                      <div style="height: 100%">
                        <img
                          :src="item.file_url"
                          alt=""
                          style="
                            max-width: 110px;
                            max-height: 110px;
                            border-radius: 5px;
                          "
                        />
                      </div>
                      <div class="deleteBTNDiv" @click="deleteAssets(item.id)">
                        <v-icon size="18" color="red">mdi-delete</v-icon>
                      </div>
                    </div>
                    <div class="imageVideoDiv" v-else>
                      <video
                        :src="item.file_url"
                        style="
                          width: 110px;
                          height: 110px;
                          object-fit: cover;
                          border-radius: 5px;
                        "
                      ></video>
                      <img
                        src="@/assets/play.png"
                        width="30"
                        style="position: relative; bottom: 35%; right: 60%"
                      />
                      <div class="deleteBTNDiv" @click="deleteAssets(item.id)">
                        <v-icon size="18" color="red">mdi-delete</v-icon>
                      </div>
                    </div>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row class="px-4 pt-7" no-gutters>
            <v-col class="pr-4">
              <v-text-field
                label="Student First Name"
                outlined
                dense
                v-model="firstName"
                :rules="[rules.required]"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col class="pl-4">
              <v-text-field
                label="Student Last Name"
                outlined
                dense
                v-model="lastName"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
                  @blur="checkStudentName()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-4" no-gutters>
            <v-col class="pr-4">
              <v-autocomplete
                label="Select School"
                outlined
                dense
                v-model="school"
                :items="schoolList"
                :rules="[rules.required]"
                item-value="id"
                item-text="school_name"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
              ></v-autocomplete>
            </v-col>
            <v-col class="pl-4">
              <v-autocomplete
                label="Select Class"
                outlined
                dense
                v-model="Class"
                :items="classList"
                :item-text="getFieldText"
                :rules="[rules.required]"
                item-value="id"
                class="formFields"
                color="#7253CF"
                :disabled="disableState"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="px-4" no-gutters>
            <v-col cols="12">
              <v-autocomplete
                label="Jersey Size"
                outlined
                dense
                v-model="jersey"
                :items="jerseySizeOptions"
                :rules="[rules.required]"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="px-4" no-gutters>
           <!-- <v-col cols="12">
              <v-textarea
                outlined
                row-height="8"
                color="#7253CF"
                class="formFields"
                :disabled="formLoading"
                v-model="specialMessage"
                label="Special Message"
              ></v-textarea>
            </v-col>-->
            <v-col cols="12">
                  <label>
                    <span> Special Message </span>
                  </label>
                  <quillEditor
                    v-model="specialMessage"
                    :options="editorOption"
                  />
                </v-col>


          </v-row>
          <v-row class="px-4" no-gutters>
            <v-col>
              <v-text-field
                label="Goal"
                outlined
                dense
                type="number"
                v-model="goal"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="#067605">mdi-currency-usd</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <div>Student Page Privacy ⓘ</div>
              <v-radio-group
                class="radio-group py-0 ma-0 px-1"
                small
                v-model="privacyStatus"
                row
              >
                <v-radio
                  :disabled="formLoading"
                  color="#2C1963"
                  label="Public"
                  value="public"
                ></v-radio>
                <v-radio
                  :disabled="formLoading"
                  color="#2C1963"
                  label="Private"
                  value="private"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions
        class="pt-5 pb-7 d-flex justify-center actionSection-text"
      v-if="$vuetify.breakpoint.smAndUp">
        <v-btn
          text
          style="border: 2px solid #2c1963; border-radius: 12px"
          height="45"
          :class="
            $vuetify.breakpoint.smAndUp
              ? 'px-10 text-capitalize'
              : 'text-capitalize'
          "
          @click="submitForm1(true)"
          :loading="AddAnotherbtnstatus"
          :disabled="addContinuebtnStatus"
          v-if="type === 'add'"
        >
          <span style="color: #2c1963">Save & add another Student</span>
        </v-btn>
        <v-btn
          style="border-radius: 12px"
          :class="
            $vuetify.breakpoint.smAndUp
              ? 'px-10 text-capitalize'
              : ' text-capitalize'
          "
          height="45"
          :disabled="AddAnotherbtnstatus"
          :loading="addContinuebtnStatus"
          dark
          color="#CC0000"
           @click="submitForm(false)"
        >
          <span>Save & Proceed</span>
        </v-btn>
      </v-card-actions>
         <v-card-actions
  class="pt-5 pb-7 d-flex justify-center actionSection-text"
  v-if="$vuetify.breakpoint.xsOnly"
>
  <v-container class="px-0" fluid>
    <v-row dense>
      <!-- First Button -->
      <v-col cols="12">
        <v-btn
          text
          style="border: 2px solid #2c1963; border-radius: 12px; font-size: 9px;"
          height="45"
          :class="[
            'text-capitalize',
            $vuetify.breakpoint.smAndUp ? 'px-10' : ''
          ]"
          @click="submitForm1(true)"
          :loading="AddAnotherbtnstatus"
          :disabled="addContinuebtnStatus"
          v-if="type === 'add'"
        >
          <span style="color: #2c1963">Save & Add Another Student</span>
        </v-btn>
      </v-col>

      <!-- Second Button -->
      <v-col cols="12">
        <v-btn
          style="border-radius: 12px;"
          :class="[
            'text-capitalize',
            $vuetify.breakpoint.smAndUp ? 'px-10' : ''
          ]"
          height="45"
          :disabled="AddAnotherbtnstatus"
          :loading="addContinuebtnStatus"
          dark
          color="#CC0000"
          @click="submitForm(false)"
        >
          <span>Save & Proceed</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</v-card-actions>

    </v-card>
    <DuplicateStudentAlertModal></DuplicateStudentAlertModal>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {} from "@/constants/APIKeys";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import {
  API_STUDENT_STORE_ASSETS,
  API_ADMIN_GET_SCHOOL_LIST,
  API_MASTER_GET_GRADE,
  API_PDP_CHECK_STUDENT_NAME,
  API_ADMIN_STUDENT_MANAGEMENT_POST,
  API_MASTER_GET_MESSAGE,
} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
import authToken from "@/common/authToken";
import Axios from "@/api/BaseAxios";
import { INITIAL_ZERO_STUDENT } from "@/constants/ModuleKeys";
import DuplicateStudentAlertModal from "@/components/PublicDonationPages/DuplicateStudentAlertModal/DuplicateStudentAlertModal";
export default {
  name: "CreateStudentModal",
  components: { DuplicateStudentAlertModal ,  quillEditor,
 },
  data() {
    return {
      signUpPageFlag: true,
      modalActionText: {
        title: "Parent Sign up",
        actionSub: "Already have an account? ",
        actionBtn: "Login",
      },
      rules: RULES,
      signUp: {
        name: "",
        email: "",
        password: "",
      },
      login: {
        email: "",
        password: "",
      },
      imgFile: {},
      imgAssets: [],
      formLoading: false,
      loading: false,
      fileLoader: false,
      previewImage: null,
      firstName: "",
      lastName: "",
      school: null,
      schoolList: [],
      Class: null,
      classList: [],
      jersey: "",
      specialMessage: "",
      goal: null,
      privacyStatus: "public",
      jerseySizeOptions: [
        "Small",
        "Medium",
        "Large",
        "Adult Small",
        "Adult Medium",
      ],
      fileCount: 0,
      fileLenght: null,
      assestStoreList: [],
      addContinuebtnStatus: false,
      AddAnotherbtnstatus: false,
      videoflag: false,
      
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      showGetter: "publicDonationPage/getCreateStudentModalShow",
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getCampaignDetail() {
      return this.getCampaign;
    },
    type() {
      return this.$store.state.publicDonationPage.createStudentModal.actionType;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleModal({ show: value });
        
      },
    },
    disableState() {
      let state = true;
      if (this.school) {
        state = false;
      } else {
        state = true;
      }
      return state;
    },
    parentDetail() {
      return authToken.decodedToken();
    },
    progressPercentage() {
      return Math.trunc((this.fileCount / this.fileLenght) * 100);
    },
    tostMessage() {
      if (this.type === "add") {
        return "Student Added";
      } else {
        return "Student Updated";
      }
    },
  },
  watch: {
    school: function () {
      if (this.school) {
        this.getSpecialMessage();
      }
    },
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.clearModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleModal: "publicDonationPage/toggleCreateStudentModal",
      toggleParentVerifyEmailModal:
        "publicDonationPage/toggleParentVerifyEmailModal",
      toggleResetPasswordModal: "publicDonationPage/toggleResetPasswordModal",
      toggleSelectStudentModal: "publicDonationPage/toggleSelectStudentModal",
      showToast: "snackBar/showToast",
      toggleDuplicateStudentAlertModal:
        "publicDonationPage/toggleDuplicateStudentAlertModal",
       
    }),
    
    openModal() {
      if (this.type === "add") {
        this.getSchoolList();
        this.goal = this.getCampaignDetail.campaign_detail.student_default_goal;
        let campaign_type =
          this.getCampaignDetail.campaign_detail.campaign_type;
        if (campaign_type === "school") {
          this.school = this.getCampaignDetail.campaign_detail.school;
        }
      } else {
        this.getStudentDetail();
      }
      let date = new Date();
      console.log(date.getDay(), "date");
      console.log(this.getCampaignDetail, "campaign");
    },
    clearModal() {
      this.signUpPageFlag = true;
      this.modalActionText.title = "Parent Sign up";
      this.modalActionText.actionSub = "Already have an account? ";
      this.modalActionText.actionBtn = "Login";
      this.signUp.name = "";
      this.signUp.email = "";
      this.signUp.password = "";
      this.login.email = "";
      this.login.password = "";
      this.previewImage = null;
      this.imgFile = {};
      this.fileCount = 0;
      this.fileLenght = null;
      this.imgAssets = [];
      this.school = null;
      this.Class = null;
      this.schoolList = [];
      this.classList = [];
      this.firstName = "";
      this.lastName = "";
      this.jersey = "";
      this.specialMessage = "";
      this.goal = null;
      this.privacyStatus = "public";
      this.assestStoreList = [];
      this.fileLoader = false;
      this.AddAnotherbtnstatus = false;
      this.addContinuebtnStatus = false;
    },

    previewProfilePhoto(payload) {
      console.log(payload, "payload");
      // let file = payload;
      // this.imgFile = payload;
      // if (file) {
      //   this.previewImage = URL.createObjectURL(file);
      //   URL.revokeObjectURL(file);
      // } else {
      //   this.previewImage = null;
      // }

      let input = this.$refs.fileInput;
      let file = input.files;
      this.imgFile = file[0];
      console.log(this.imgFile);
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      } else {
        this.previewImage = null;
      }
    },
    uploadAssets() {
      let input = this.$refs.inputGallery;
      let file = input.files;
      console.log(file, "files");
      this.imgAssets = file;

      this.fileCount = 0;
      this.fileLenght = Object.keys(this.imgAssets).length;
      if (this.fileLenght) this.fileLoader = true;
      for (const obj of this.imgAssets) {
        const successHandler = (res) => {
          console.log(res.data);
          let img = res.data.campaign_assests_detail;
          this.assestStoreList.push({
            file: img.file,
            file_url: img.file_url,
            id: img.id,
            type: img.type,
          });
          this.fileCount = this.fileCount + 1;
          if (this.fileCount === this.fileLenght) this.fileLoader = false;
        };
        const failureHandler = (res) => {
          console.log(res);
          this.fileLoader = false;
        };
        let formData = new FormData();
        console.log(obj);
        formData.append("file", obj);
        formData.append("type", obj.type.split("/")[0]);
        formData.append("campaign", this.getCampaignDetail.campaign_detail.id);
        Axios.request_POST(
          API_STUDENT_STORE_ASSETS,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
    deleteAssets(ID) {
      console.log(ID);
      const successHandler = (res) => {
        console.log(res);
        this.assestStoreList.splice(
          this.assestStoreList.findIndex((item) => item.id == ID),
          1
        );
        this.showToast({
          message: "Deleted",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Something went wrong",
          color: "e",
        });
      };
      let formData = {};
      formData["assests_id"] = ID;
      Axios.request_DELETE(
        API_STUDENT_STORE_ASSETS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getStudentDetail() {
      this.loading = true;
      const successHandler = (res) => {
        this.previewImage = res.data.student_detail.profile_pic;
        this.assestStoreList = res.data.student_assests_list;
        this.firstName = res.data.student_detail.student_first_name;
        this.lastName = res.data.student_detail.student_last_name;
        this.school = res.data.student_detail.school;
        this.Class = res.data.student_detail.teacher;
        this.jersey = res.data.student_detail.jersey_size;
        this.specialMessage = res.data.student_detail.student_special_message;
        this.goal = res.data.student_detail.student_goal;
        this.privacyStatus = res.data.student_detail.privacy;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      const finallyHandler = () => {
        this.getSchoolList();
      };
      let formData = {};
      formData["student_id"] =
        this.$store.state.publicDonationPage.createStudentModal.studentID;
      Axios.request_GET(
        API_ADMIN_STUDENT_MANAGEMENT_POST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getSpecialMessage() {
      const successHandler = (res) => {
        if (res.data.special_message_list[0]) {
          this.specialMessage = res.data.special_message_list[0].message;
          this.loading = false;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getClassList();
      };
      let formData = {};
      formData["message_for"] = "student";
      formData["message_type"] = "special_message";
      formData["campaign"] = this.getCampaignDetail.campaign_detail.id;
      formData["school"] = this.school;
      Axios.request_GET(
        API_MASTER_GET_MESSAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getSchoolList() {
      this.loading = true;
      const successHandler = (res) => {
        this.schoolList = res.data.school_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getClassList();
      };
      let formData = {};
      formData["campaign_id"] = this.getCampaignDetail.campaign_detail.id;
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getFieldText(item) {
      return `${item.teacher_name} - ${item.grade_title}`;
    },
    getClassList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.classList = data.grade_list;
        self.loading = false;
      };
      const failureHandler = (res) => {
        const data = res.data;
        self.loading = false;
        console.log(data);
      };
      const formData = {};
      formData["campaign_id"] = this.getCampaignDetail.campaign_detail.id;
      formData["school_id"] = this.school;
      Axios.request_GET(
        API_MASTER_GET_GRADE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    submitForm(status) {
    this.videoflag=true;
   localStorage.setItem("vidoflagvalue", this.videoflag)
    this.alertflag=true;
   localStorage.setItem("alertflagvalue", this.alertflag)
    console.log("in submit detail &&&&&&&&&&&")
      if (this.$refs.form.validate()) {
        let check = status;
        let campaign_id = this.getCampaignDetail.campaign_detail.id;
        if (check) {
          this.AddAnotherbtnstatus = true;
        } else {
          this.addContinuebtnStatus = true;
        }
        this.formLoading = true;
        const successHandler = (res) => {
          console.log(res);
          this.showToast({
            message: this.tostMessage,
            color: "s",
          });
          if (check) {
            this.toggleModal({ show: false });
            setTimeout(() => {
              this.$emit("openAgain");
            }, 200);
          } else {
            if (
              this.$store.state.publicDonationPage.createStudentModal.type ===
              INITIAL_ZERO_STUDENT
            ) {
              this.toggleSelectStudentModal({ show: true });
             
            }
            this.toggleModal({ show: false });
          
           this.clearModal();
          }
          if (
            this.$store.state.publicDonationPage.createStudentModal.type ===
            INITIAL_ZERO_STUDENT
          ) {
            this.toggleSelectStudentModal({ show: true });
          }
          this.formLoading = false;
          this.AddAnotherbtnstatus = false;
          this.addContinuebtnStatus = false;
        };
        const failureHandler = (res) => {
          console.log(res);
          this.showToast({
            message: "Something went wrong",
            color: "e",
          });
          this.formLoading = false;
          this.AddAnotherbtnstatus = false;
          this.addContinuebtnStatus = false;
        };
        const finallyHandler = () => {
          console.log("in here update");
          console.log(this.type);
          if (this.type === "add") {
            this.$root.$refs.studentCard.getStudentListData();
          } else {
            location.reload();
          }
        };
        let formData = new FormData();
        formData.append("school", this.school);
        formData.append("campaign", campaign_id);
        if (this.imgFile instanceof File) {
          formData.append("profile_pic", this.imgFile);
        }
        formData.append("student_first_name", this.firstName);
        formData.append("student_last_name", this.lastName);
        formData.append("parent_email", this.parentDetail.user_email);
        formData.append("parent_name", this.parentDetail.user_name);
        formData.append("student_goal", this.goal);
        formData.append("jersey_size", this.jersey);
        formData.append("student_special_message", this.specialMessage);
        formData.append("teacher", this.Class);
        if (this.assestStoreList[0]) {
          formData.append("assests_list", JSON.stringify(this.assestStoreList));
        }
        formData.append("privacy", this.privacyStatus);
        if (this.type === "add") {
          Axios.request_POST(
            API_ADMIN_STUDENT_MANAGEMENT_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false,
            true,
            finallyHandler
          );
        } else {
          formData.append(
            "student_id",
            this.$store.state.publicDonationPage.createStudentModal.studentID
          );
          Axios.request_PATCH(
            API_ADMIN_STUDENT_MANAGEMENT_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false,
            true,
            finallyHandler
          );
        }
         console.log("in submit detail &&&&&&&&&&&",this.formData)
      }
    },



      submitForm1(status) {
    this.videoflag=true;
   localStorage.setItem("vidoflagvalue", this.videoflag)
    this.alertflag=true;
   localStorage.setItem("alertflagvalue", this.alertflag)
    console.log("in submit detail &&&&&&&&&&&")
      if (this.$refs.form.validate()) {
        let check = status;
        let campaign_id = this.getCampaignDetail.campaign_detail.id;
        if (check) {
          this.AddAnotherbtnstatus = true;
        } else {
          this.addContinuebtnStatus = true;
        }
        this.formLoading = true;
        const successHandler = (res) => {
          console.log(res);
          this.showToast({
            message: this.tostMessage,
            color: "s",
          });
          if (check) {
            this.toggleModal({ show: false });
            setTimeout(() => {
              this.$emit("openAgain");
            }, 200);
          } else {
            if (
              this.$store.state.publicDonationPage.createStudentModal.type ===
              INITIAL_ZERO_STUDENT
            ) {
              this.toggleSelectStudentModal({ show: true });
             
            }
            this.toggleModal({ show: false });
          
           //this.clearModal();
          }
          if (
            this.$store.state.publicDonationPage.createStudentModal.type ===
            INITIAL_ZERO_STUDENT
          ) {
            this.toggleSelectStudentModal({ show: true });
          }
          this.formLoading = false;
          this.AddAnotherbtnstatus = false;
          this.addContinuebtnStatus = false;
        };
        const failureHandler = (res) => {
          console.log(res);
          this.showToast({
            message: "Something went wrong",
            color: "e",
          });
          this.formLoading = false;
          this.AddAnotherbtnstatus = false;
          this.addContinuebtnStatus = false;
        };
        const finallyHandler = () => {
          console.log("in here update");
          console.log(this.type);
          if (this.type === "add") {
            this.$root.$refs.studentCard.getStudentListData();
          } else {
           // location.reload();
          }
        };
        let formData = new FormData();
        formData.append("school", this.school);
        formData.append("campaign", campaign_id);
        if (this.imgFile instanceof File) {
          formData.append("profile_pic", this.imgFile);
        }
        formData.append("student_first_name", this.firstName);
        formData.append("student_last_name", this.lastName);
        formData.append("parent_email", this.parentDetail.user_email);
        formData.append("parent_name", this.parentDetail.user_name);
        formData.append("student_goal", this.goal);
        formData.append("jersey_size", this.jersey);
        formData.append("student_special_message", this.specialMessage);
        formData.append("teacher", this.Class);
        if (this.assestStoreList[0]) {
          formData.append("assests_list", JSON.stringify(this.assestStoreList));
        }
        formData.append("privacy", this.privacyStatus);
        if (this.type === "add") {
          Axios.request_POST(
            API_ADMIN_STUDENT_MANAGEMENT_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false,
            true,
            finallyHandler
          );
        } else {
          formData.append(
            "student_id",
            this.$store.state.publicDonationPage.createStudentModal.studentID
          );
          Axios.request_PATCH(
            API_ADMIN_STUDENT_MANAGEMENT_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false,
            true,
            finallyHandler
          );
        }
         console.log("in submit detail &&&&&&&&&&&",this.formData)
      }
    },
    openDuplicateStudentAlertModal(data) {
      this.toggleDuplicateStudentAlertModal({ show: true, data });
    },
    checkStudentName() {
      if (this.type === "add") {
        if (!this.isEmpty(this.firstName) && !this.isEmpty(this.lastName)) {
          console.log(
            "firstName: ",
            this.firstName,
            "lastName: ",
            this.lastName
          );
          const self = this;
          const successHandler = (res) => {
            const data = res.data;
            console.log({ data });
            if (data.student_name_exists)
              self.openDuplicateStudentAlertModal(data.student_detail);
            // self.loading = false;
          };
          const failureHandler = (res) => {
            console.log(res);
          };
          const formData = {};
          formData["student_first_name"] = self.firstName;
          formData["student_last_name"] = self.lastName;
          formData["campaign_id"] = this.getCampaignDetail.campaign_detail.id
          Axios.request_GET(
            API_PDP_CHECK_STUDENT_NAME,
            formData,
            {},
            successHandler,
            failureHandler,
            false,
            false
          );
        }
      }
    },
    isEmpty(str) {
      return !str || str.length === 0;
    },
    reload()
    {
    location.reload();
    },
  },
  mounted() {
  
  }
};
</script>

<style scoped>
.modalTitle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 315%;
  color: #ffffff;
}
.signUp-btn span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 29px;
  color: #ffffff;
  letter-spacing: 0px;
  text-transform: none;
}
.actionSection-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.61);
}

.socialMediaLogin span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0px;
  text-transform: none;
}
.v-btn >>> span {
  font-size: 18px;
  font-weight: 600;
}
.profilePictureInstruction {
  color: #2c1963;
  letter-spacing: 0px;
  cursor: pointer;
}
.profilePictureInstruction >>> span {
  border-bottom: 1px solid #2c1963;
}
.profilePictureImage {
  cursor: pointer;
}
.assetsGallery {
  width: 100%;
  margin-top: 25%;
  justify-items: center;
}
.labelGallery {
  border: 1px dashed #2c1963;
  width: 100%;
  height: 100%;
  background: rgba(44, 25, 99, 0.1);
  cursor: pointer;
}
.imageVideoDiv {
  width: 110px;
  height: 110px;
  margin-top: 6px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
}
.deleteBTNDiv {
  height: 25px;
  width: 25px;
  background: white;
  position: relative;
  bottom: 100%;
  right: 2%;
  display: flex;
  padding-left: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  float: right;
  cursor: pointer;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modalTitle {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 219%;
    color: #ffffff;
  }
  .v-btn >>> span {
    font-size: 14px;
    font-weight: 600;
  }
  .deleteBTNDiv {
    height: 25px;
    width: 25px;
    background: white;
    position: relative;
    bottom: 100%;
    right: 2%;
    display: flex;
    padding-left: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    float: right;
    cursor: pointer;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
</style>
